import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1 3868 c1 -680 5 -1105 10 -1063 57 466 239 904 529 1270 60 75 231
252 310 321 230 199 480 347 760 452 173 65 331 106 495 131 l130 19 -1117 1
-1118 1 1 -1132z"/>
<path d="M2790 4993 c240 -36 245 -37 390 -80 424 -123 754 -318 1066 -628
153 -153 213 -224 322 -387 183 -272 329 -637 381 -953 7 -38 14 -83 17 -100
15 -94 23 -352 14 -486 -20 -297 -71 -517 -189 -812 -108 -271 -305 -563 -535
-792 -111 -110 -134 -131 -261 -227 -276 -209 -554 -343 -890 -427 -482 -122
-943 -105 -1408 50 -522 174 -976 522 -1284 984 -210 313 -346 676 -398 1060
-11 75 -13 -103 -14 -1052 l-1 -1143 2500 0 2500 0 0 2500 0 2500 -1117 -1
c-615 -1 -1107 -3 -1093 -6z"/>
<path d="M2245 4870 c-250 -32 -453 -84 -655 -167 -778 -320 -1330 -1033
-1447 -1870 -31 -226 -18 -609 29 -803 87 -361 218 -649 420 -923 73 -98 100
-129 223 -252 134 -135 162 -159 273 -239 314 -224 578 -339 970 -422 166 -35
523 -44 719 -19 122 16 326 61 429 94 146 47 354 140 473 212 34 22 65 39 67
39 3 0 10 5 17 10 7 6 57 43 112 82 55 39 105 76 111 82 6 6 47 42 90 81 69
60 141 137 239 250 22 26 128 173 155 215 5 8 21 32 34 52 47 70 174 343 211
451 57 169 85 289 116 502 20 136 15 458 -11 620 -80 514 -307 963 -670 1325
-289 288 -647 498 -1025 599 -66 18 -136 37 -155 42 -131 36 -566 59 -725 39z
m250 -90 c62 -30 90 -85 90 -180 0 -61 -4 -82 -24 -113 -33 -54 -84 -77 -169
-77 -60 0 -74 4 -109 29 -50 36 -73 91 -73 175 0 57 4 69 33 108 18 24 48 51
67 60 48 24 133 23 185 -2z m440 -23 l90 -23 5 -97 5 -98 44 81 c25 44 49 80
55 80 6 0 49 -11 96 -23 73 -19 85 -26 88 -45 4 -25 -15 -55 -29 -47 -4 3 -13
-11 -18 -32 -6 -21 -20 -72 -32 -113 -14 -50 -18 -75 -10 -78 13 -4 15 -58 2
-66 -15 -9 -153 27 -158 41 -9 23 6 60 25 65 14 4 24 24 36 73 9 37 15 69 13
72 -5 4 -31 -39 -82 -132 -39 -71 -37 -70 -100 -56 l-55 12 0 122 c-1 126 -7
134 -30 35 -10 -45 -10 -61 -1 -72 7 -8 9 -25 5 -41 -6 -24 -10 -26 -40 -21
-19 3 -50 9 -69 12 -19 3 -37 10 -40 14 -9 14 16 69 32 72 25 5 77 218 54 218
-6 0 -11 4 -11 9 0 18 19 61 27 61 5 0 49 -11 98 -23z m-988 -84 c53 -25 86
-83 91 -158 5 -73 -11 -111 -62 -150 -33 -25 -193 -85 -227 -85 -19 0 -37 62
-19 68 16 6 12 31 -21 129 -24 72 -35 93 -49 93 -12 0 -21 10 -25 31 -4 17 -5
32 -3 33 1 2 39 15 83 29 95 30 182 34 232 10z m-499 -203 c17 -27 32 -53 32
-57 0 -5 -18 -18 -40 -31 -37 -21 -42 -21 -64 -7 -27 18 -72 7 -83 -21 -12
-33 12 -37 105 -18 77 15 93 16 121 4 69 -28 78 -118 19 -179 -38 -39 -66 -51
-114 -51 -30 0 -34 -3 -29 -19 4 -14 -3 -24 -28 -40 -19 -12 -38 -21 -43 -21
-5 0 -24 23 -42 51 -37 56 -34 67 19 95 22 11 34 11 52 3 33 -15 81 7 85 40 3
18 0 22 -15 17 -143 -42 -180 -42 -225 2 -23 21 -28 34 -28 74 0 39 6 54 31
83 32 36 93 60 124 49 14 -6 16 -3 11 13 -5 16 1 25 26 42 18 11 38 21 43 21
6 0 25 -23 43 -50z m2232 1 c14 -9 34 -35 44 -56 11 -22 21 -34 24 -27 6 19
34 14 67 -12 l29 -24 -37 -56 -37 -56 -40 26 c-33 22 -40 32 -40 60 0 25 -7
38 -25 50 -26 17 -45 12 -45 -12 0 -8 23 -45 50 -81 41 -54 50 -73 50 -107 0
-48 -30 -89 -73 -100 -60 -14 -157 36 -178 92 -7 19 -13 22 -21 14 -14 -14
-88 24 -88 45 0 14 48 93 64 104 5 4 26 -5 47 -20 27 -19 36 -32 33 -46 -5
-26 25 -64 55 -67 39 -5 35 16 -14 73 -68 80 -77 135 -31 192 31 39 116 43
166 8z m-895 -219 c22 -6 67 -16 100 -22 98 -20 263 -79 380 -135 289 -140
539 -364 718 -643 98 -153 190 -388 234 -597 23 -105 26 -146 27 -325 1 -202
-4 -252 -40 -410 -72 -311 -220 -583 -443 -814 -264 -273 -563 -436 -936 -510
-369 -73 -778 -15 -1127 160 -216 108 -423 277 -569 464 -24 30 -46 57 -50 60
-3 3 -26 37 -51 75 -232 364 -329 788 -273 1191 30 212 74 354 175 559 88 178
176 300 320 446 283 285 603 445 1015 509 75 11 466 5 520 -8z m1285 -89 c18
-21 42 -48 53 -60 20 -22 20 -23 2 -44 -12 -12 -27 -18 -39 -15 -28 7 -146
-110 -146 -146 0 -33 23 -52 54 -44 36 9 146 114 139 132 -6 16 23 54 41 54
12 0 106 -104 106 -117 0 -12 -42 -36 -65 -37 -11 -1 -46 -26 -78 -56 -74 -70
-92 -79 -147 -80 -59 0 -111 34 -139 93 -41 85 -27 129 73 225 42 41 63 68 59
78 -6 16 23 54 42 54 6 0 27 -17 45 -37z m-3091 8 c11 -10 20 -28 19 -39 -4
-28 140 -177 157 -162 8 7 19 2 35 -15 l24 -26 -61 -61 -61 -61 -26 19 c-18
13 -25 25 -21 40 6 29 -139 181 -158 165 -11 -8 -19 -5 -37 14 l-24 25 59 60
c33 33 62 60 66 60 3 0 16 -8 28 -19z m-251 -303 c-2 -11 38 -58 106 -127
l109 -109 -32 -51 c-19 -29 -41 -51 -50 -51 -10 0 -44 9 -76 20 -32 11 -64 20
-71 20 -8 0 15 -28 51 -62 l65 -63 -31 -54 c-35 -61 -18 -61 -199 -4 -89 28
-130 37 -141 30 -11 -7 -24 -4 -42 8 l-27 17 26 42 c14 22 33 56 41 74 9 17
22 32 28 32 20 0 56 -31 53 -47 -2 -10 17 -22 61 -39 77 -29 94 -30 62 -3 -13
11 -53 46 -88 79 l-65 60 26 45 c17 28 34 45 46 45 10 0 57 -13 103 -30 47
-16 89 -30 93 -30 4 0 -17 22 -47 49 -34 31 -58 46 -65 40 -12 -9 -64 10 -64
24 0 5 16 38 36 73 l36 64 29 -17 c18 -11 28 -25 27 -35z m3747 -233 c59 -110
70 -149 55 -189 -11 -31 -71 -60 -105 -52 -18 5 -24 0 -31 -26 -6 -20 -24 -42
-44 -56 -64 -43 -124 -14 -175 85 -11 21 -35 64 -52 96 -18 33 -33 62 -33 67
0 4 11 15 25 24 19 12 29 14 42 6 14 -9 35 -2 102 34 47 25 88 46 93 46 4 0 8
11 8 23 0 23 33 49 54 43 4 -1 32 -47 61 -101z m-4219 -980 c-9 -57 7 -59 20
-2 8 37 12 42 40 45 30 3 32 0 43 -42 14 -58 23 -59 19 -3 l-3 42 38 3 c41 3
59 -17 38 -42 -6 -8 -20 -45 -29 -82 l-18 -69 -43 -3 c-24 -2 -41 0 -38 5 3 4
-1 22 -8 38 -11 26 -13 27 -14 8 -2 -42 -12 -53 -50 -53 l-37 0 -18 73 c-34
137 -35 127 19 127 l47 0 -6 -45z m389 24 c10 -30 -4 -59 -28 -59 -21 -1 -21
-2 6 -21 30 -23 34 -53 12 -82 -14 -20 -33 -22 -123 -19 l-53 2 3 38 c2 20 9
38 16 38 25 3 27 5 10 15 -12 7 -18 22 -18 49 0 50 16 59 102 58 57 0 68 -3
73 -19z m4113 5 c8 -2 12 -16 10 -36 -2 -25 -9 -34 -28 -39 -14 -4 -20 -7 -13
-8 20 -2 43 -31 43 -55 0 -31 -33 -59 -67 -58 -16 1 -49 2 -75 2 l-48 0 0 36
c0 27 5 37 19 41 20 5 20 5 0 26 -10 11 -19 29 -19 39 0 40 -18 14 -34 -49
-10 -38 -20 -74 -22 -81 -2 -9 -17 -12 -41 -10 -33 3 -38 7 -46 38 l-10 35
-10 -38 c-9 -36 -11 -38 -46 -35 -36 3 -36 3 -51 70 -8 36 -19 70 -23 74 -4 4
-7 18 -7 31 0 21 4 23 46 23 l45 0 -7 -45 c-4 -28 -2 -45 4 -45 5 0 13 20 16
45 7 43 9 45 41 45 31 0 35 -3 41 -35 9 -51 28 -57 19 -6 l-7 41 46 0 c34 0
46 -4 46 -15 0 -12 6 -11 32 4 24 15 43 18 82 14 28 -2 57 -7 64 -9z m-4170
-655 c8 -33 8 -41 -4 -46 -8 -3 -17 -3 -21 0 -6 7 -63 -20 -63 -29 0 -4 25 -3
55 1 52 6 55 5 61 -19 8 -32 1 -42 -47 -66 -21 -11 -39 -23 -39 -25 0 -8 53 6
64 16 21 19 43 8 53 -26 6 -20 7 -35 2 -36 -5 0 -50 -1 -100 -1 -97 -1 -109 5
-109 49 0 13 8 24 19 28 11 3 25 13 32 22 12 14 9 15 -29 8 -39 -6 -43 -5 -52
20 -15 40 -14 41 46 76 31 18 64 40 73 51 26 30 47 22 59 -23z m3939 1 c41
-25 49 -56 33 -131 -13 -61 -33 -76 -59 -45 -15 18 -90 37 -118 30 -11 -3 -23
-1 -27 4 -6 10 14 78 35 118 23 45 85 56 136 24z m-32 -222 c14 -6 25 -14 25
-18 0 -18 -49 -135 -57 -137 -5 -2 -22 8 -37 22 -15 14 -36 25 -46 25 -10 0
-22 5 -26 11 -3 6 -17 8 -29 5 -31 -8 -39 7 -25 49 8 24 18 35 31 35 10 0 19
-5 19 -10 0 -15 91 -52 109 -45 14 6 14 8 -2 20 -13 9 -17 20 -12 34 7 24 14
25 50 9z m-3806 -57 c21 -44 19 -51 -15 -51 -33 0 -110 -36 -117 -54 -10 -27
-32 -18 -50 19 -23 48 -22 55 12 55 39 0 121 38 121 55 0 29 30 14 49 -24z
m55 -113 c9 -12 16 -29 16 -36 0 -8 9 -26 21 -40 l20 -26 -23 -18 c-35 -27
-53 -19 -54 25 -1 21 -6 43 -13 50 -8 8 -11 -4 -11 -50 0 -66 -15 -93 -51 -93
-31 0 -69 44 -69 81 0 18 -3 29 -7 26 -3 -4 -13 2 -21 14 -8 11 -12 21 -11 23
58 44 79 37 79 -25 0 -22 5 -39 10 -39 6 0 10 24 10 53 0 29 5 58 12 65 21 21
75 15 92 -10z m3591 -3 c7 -14 18 -19 38 -17 15 2 25 8 22 13 -9 13 19 11 33
-3 17 -17 15 -53 -3 -68 -10 -8 -29 -10 -56 -6 -31 5 -40 3 -37 -6 3 -7 19
-14 36 -16 37 -4 39 -15 12 -59 -19 -32 -50 -37 -50 -9 0 14 -82 56 -110 56
-28 0 -33 16 -14 52 37 72 54 89 87 86 19 -2 36 -11 42 -23z m-3487 -188 c40
-66 41 -100 3 -137 -58 -58 -110 -50 -164 26 -37 53 -40 67 -12 72 32 6 103
55 108 74 9 32 32 19 65 -35z m3428 -1 c40 -40 44 -75 14 -123 -36 -60 -115
-60 -165 -1 -29 34 -32 66 -10 108 19 37 41 49 89 50 30 0 45 -7 72 -34z
m-190 -128 c3 -13 24 -50 47 -82 41 -62 42 -76 4 -100 -12 -8 -24 -6 -47 8
-41 24 -45 17 -16 -23 l25 -33 -24 -25 -23 -25 -68 36 c-38 20 -76 36 -86 36
-26 0 -22 32 6 60 26 23 56 22 56 -3 0 -8 11 -19 25 -25 32 -15 32 -8 -5 38
-30 37 -30 38 -11 61 l18 23 43 -23 c23 -12 44 -20 46 -18 5 4 -23 33 -49 50
-18 12 -17 14 3 40 26 33 46 35 56 5z m-3097 -53 c41 -47 41 -85 -1 -130 -22
-24 -45 -38 -70 -42 -33 -5 -41 -2 -73 30 -28 28 -35 43 -35 75 0 33 6 45 35
71 52 45 102 43 144 -4z m130 -142 c25 -22 25 -23 8 -59 -10 -20 -16 -39 -13
-41 3 -3 19 5 36 17 l31 22 37 -29 c45 -37 50 -51 20 -62 -26 -10 -75 -64 -89
-98 -11 -29 -19 -29 -54 1 -28 24 -30 56 -2 56 7 0 24 12 38 26 l24 27 -25
-13 c-14 -7 -38 -22 -53 -33 l-29 -20 -21 22 -21 21 22 45 c27 56 27 60 2 35
-11 -11 -20 -27 -20 -35 0 -24 -29 -29 -50 -10 -27 24 -25 44 3 54 27 10 77
66 77 85 0 6 5 17 12 24 12 12 18 10 67 -35z m2711 -92 c1 -9 21 -41 46 -71
l44 -55 -21 -17 c-26 -22 -38 -22 -89 -2 -23 9 -43 14 -47 11 -8 -9 23 -37 41
-37 31 0 29 -24 -4 -51 -36 -31 -50 -30 -50 4 0 12 -20 41 -50 69 -27 26 -50
52 -50 57 0 5 12 17 27 26 23 16 33 16 70 7 59 -16 68 -15 53 3 -7 8 -19 15
-27 15 -27 0 -27 31 2 56 30 25 55 19 55 -15z m-2422 -136 c65 -42 70 -50 48
-80 -17 -23 -43 -14 -56 20 -11 29 -25 32 -34 9 -4 -10 1 -25 11 -36 22 -24 5
-45 -25 -29 -24 13 -33 5 -26 -24 9 -36 -14 -39 -57 -10 -39 28 -52 55 -25 55
20 0 70 67 71 94 2 26 14 48 24 44 4 -2 35 -21 69 -43z m2225 -29 c4 -6 6 -20
5 -31 -3 -22 34 -48 56 -39 23 9 31 -34 10 -57 -23 -26 -50 -24 -76 6 -11 13
-25 22 -29 19 -13 -8 1 -32 22 -37 29 -8 23 -30 -15 -51 -36 -19 -56 -15 -56
11 0 21 -38 84 -63 104 -28 23 -18 40 44 72 52 27 85 28 102 3z m-2042 -79
c36 -24 45 -49 35 -102 -14 -71 -81 -103 -146 -70 -92 47 -50 191 56 194 12 1
36 -9 55 -22z m1869 2 c18 -32 5 -50 -32 -47 -24 2 -33 -1 -33 -12 0 -14 13
-17 44 -9 8 2 17 -4 19 -14 2 -12 -3 -20 -17 -24 -24 -6 -23 -5 -15 -27 9 -24
33 -19 44 9 9 23 50 37 50 18 0 -5 3 -17 6 -29 5 -18 -5 -26 -64 -57 -59 -30
-72 -34 -82 -22 -6 8 -9 19 -5 24 8 14 -32 92 -59 113 -26 21 -20 27 59 67 72
36 71 36 85 10z m-1723 -70 c26 -11 56 -28 66 -36 23 -21 22 -60 -3 -75 -21
-14 -29 -70 -9 -65 19 4 21 -20 4 -37 -36 -36 -80 -6 -78 53 1 23 -2 41 -8 41
-5 0 -9 -15 -9 -33 0 -19 -3 -36 -6 -39 -3 -3 -23 1 -45 11 -40 16 -50 37 -24
47 8 4 15 10 15 14 0 4 8 25 17 46 9 22 16 45 14 53 -3 14 6 41 13 41 2 0 26
-9 53 -21z m1558 -45 c25 -27 30 -46 26 -94 -3 -36 -25 -55 -88 -81 -46 -18
-56 -20 -64 -8 -5 8 -6 20 -4 28 7 18 -25 103 -42 114 -25 15 -13 35 30 50 68
25 114 21 142 -9z m-1254 -55 c49 -12 89 -25 89 -29 0 -4 -5 -21 -11 -37 -8
-24 -15 -30 -30 -26 -11 3 -19 14 -19 26 0 16 -3 18 -12 9 -6 -6 -12 -41 -12
-78 -1 -36 -3 -66 -6 -66 -101 15 -113 22 -82 55 12 12 25 42 31 67 11 48 6
66 -10 37 -11 -18 -34 -23 -43 -8 -6 10 3 71 11 71 2 0 45 -10 94 -21z m1044
-2 c35 -29 46 -104 21 -141 -22 -35 -85 -53 -125 -37 -32 14 -61 63 -61 103 1
82 103 129 165 75z m-195 -31 c0 -8 -4 -17 -9 -21 -12 -7 -3 -70 16 -112 19
-42 11 -53 -39 -53 l-40 0 6 34 c4 18 2 44 -2 57 -7 19 -10 12 -16 -39 l-7
-62 -33 0 c-29 0 -34 4 -46 40 -7 22 -17 40 -22 40 -13 0 -10 -19 9 -51 20
-33 19 -35 -27 -44 l-35 -6 -1 77 c-1 50 -5 79 -12 82 -7 2 -12 12 -12 22 0
12 10 19 38 24 63 10 68 8 82 -34 17 -52 28 -51 32 2 2 35 7 43 28 49 46 13
90 10 90 -5z m-664 -11 c4 -8 1 -22 -6 -30 -15 -18 -6 -45 15 -45 21 0 30 27
15 46 -20 23 1 36 48 28 31 -5 42 -11 42 -24 0 -10 -6 -20 -12 -23 -9 -2 -13
-28 -13 -78 -1 -80 3 -75 -60 -71 -28 2 -32 21 -13 52 14 22 11 27 -17 32 -17
3 -20 -2 -18 -35 l3 -38 -47 3 c-51 3 -63 17 -38 42 11 11 16 36 17 86 l2 70
38 0 c25 0 41 -5 44 -15z m302 -42 c3 -28 0 -33 -19 -33 -12 0 -27 7 -34 17
-7 9 -17 13 -23 9 -20 -12 -13 -34 11 -38 32 -4 28 -32 -5 -36 -20 -2 -28 -9
-28 -23 0 -20 34 -27 45 -9 9 14 43 12 48 -2 2 -7 2 -21 -1 -30 -4 -15 -17
-18 -88 -18 -83 0 -101 8 -75 36 11 11 18 111 8 120 -4 4 -7 16 -7 26 0 17 7
19 83 16 l82 -3 3 -32z"/>
<path d="M2366 4698 c-8 -13 -17 -39 -21 -59 -13 -69 29 -158 67 -144 51 20
49 187 -2 215 -26 14 -28 13 -44 -12z"/>
<path d="M1813 4612 c-13 -2 -23 -6 -23 -9 0 -14 62 -199 69 -206 4 -4 20 2
34 13 34 27 36 84 7 145 -21 44 -50 63 -87 57z"/>
<path d="M2275 4204 c-207 -27 -390 -84 -573 -179 -155 -81 -277 -170 -403
-295 -114 -113 -158 -166 -231 -280 -134 -206 -198 -365 -254 -630 -26 -123
-27 -456 0 -590 18 -95 59 -247 76 -285 5 -11 21 -49 35 -85 113 -276 329
-542 598 -734 156 -111 403 -215 637 -267 93 -21 130 -24 325 -24 193 1 233 4
325 24 107 24 220 55 255 71 11 5 49 21 85 36 239 97 488 287 660 503 53 65
130 176 130 185 0 3 13 27 29 54 73 124 151 339 180 497 52 289 38 554 -44
815 -84 265 -236 516 -429 705 -374 369 -891 546 -1401 479z m-645 -470 c28
-17 32 -17 63 -2 103 54 234 22 266 -65 24 -64 24 -65 -11 -69 -49 -5 -62 -40
-61 -168 1 -130 25 -313 69 -535 49 -251 97 -461 107 -471 2 -2 8 18 15 44 20
84 61 254 98 407 56 232 49 362 -23 437 -15 15 -42 31 -61 34 -34 7 -34 7 -28
48 8 49 45 91 94 107 53 17 161 6 211 -21 l41 -24 43 25 c35 20 55 24 127 24
74 0 89 -3 119 -24 33 -24 61 -73 61 -110 0 -13 -9 -21 -32 -25 -39 -8 -57
-21 -84 -61 -45 -64 -54 -162 -28 -291 22 -114 19 -110 57 -82 43 34 110 67
167 84 46 14 47 15 53 61 4 27 13 93 21 148 18 117 21 312 5 352 -12 33 -26
43 -58 43 -20 0 -22 3 -16 28 11 46 55 103 90 118 52 21 124 18 177 -9 l46
-23 28 20 c22 16 44 21 95 21 56 0 71 -4 101 -27 19 -15 45 -47 57 -72 25 -55
25 -56 5 -56 -100 0 -217 -207 -299 -529 -9 -35 -15 -64 -13 -66 2 -2 20 -10
41 -19 21 -8 72 -42 115 -74 84 -64 124 -82 185 -82 34 0 38 -2 31 -17 -5 -10
-9 -83 -9 -163 0 -150 -5 -171 -50 -216 -40 -40 -131 -60 -197 -44 l-46 12 5
82 c5 66 2 90 -12 120 -24 51 -64 89 -122 116 -66 31 -165 25 -222 -13 -112
-74 -135 -270 -44 -369 45 -47 116 -71 202 -66 42 3 84 8 94 12 12 6 17 4 17
-7 0 -29 44 -69 154 -141 187 -123 256 -220 278 -392 26 -200 -41 -392 -179
-513 -124 -108 -326 -163 -489 -132 -92 18 -158 50 -239 116 -97 80 -130 95
-206 95 l-60 0 18 32 c14 26 18 60 19 151 2 111 4 122 33 182 53 106 142 165
253 165 60 0 65 -11 38 -75 -26 -60 -29 -173 -6 -227 20 -46 67 -101 109 -127
18 -10 59 -25 93 -31 76 -15 162 4 231 51 127 87 140 311 25 428 -78 80 -189
108 -299 75 -19 -5 -23 -2 -23 15 0 30 -23 71 -54 99 -14 12 -29 22 -32 22
-17 0 -124 79 -169 126 -90 94 -115 152 -171 404 -14 63 -29 119 -32 125 -16
26 -81 -307 -82 -418 0 -99 8 -122 45 -131 20 -5 25 -12 25 -40 0 -74 -82
-123 -194 -114 -40 4 -79 14 -105 29 l-41 23 -41 -23 c-56 -32 -153 -40 -201
-17 -44 21 -91 66 -111 108 -16 33 -16 33 12 40 52 14 56 28 55 192 -1 163
-18 297 -65 527 -81 399 -206 674 -315 695 -39 7 -41 12 -15 62 22 43 55 76
91 91 35 15 123 6 160 -15z"/>
<path d="M4387 3563 c-31 -16 -33 -39 -4 -57 34 -22 77 35 45 61 -9 8 -21 7
-41 -4z"/>
<path d="M4255 3493 c-16 -8 -31 -17 -33 -18 -1 -1 3 -14 9 -29 24 -53 103
-24 83 31 -11 33 -22 36 -59 16z"/>
<path d="M550 2641 c0 -5 7 -11 15 -15 9 -3 15 0 15 9 0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"/>
<path d="M525 2560 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M4665 2639 c-4 -5 2 -12 12 -16 10 -3 21 -8 25 -10 4 -2 5 3 2 11 -7
18 -31 28 -39 15z"/>
<path d="M4641 2554 c13 -16 39 -19 39 -5 0 5 -11 11 -25 13 -20 4 -23 3 -14
-8z"/>
<path d="M4431 1981 c-21 -13 -7 -37 26 -44 15 -4 38 -9 51 -13 15 -4 22 -2
22 8 0 33 -70 68 -99 49z"/>
<path d="M4262 1588 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>
<path d="M768 1386 c-36 -25 -45 -35 -36 -44 19 -19 41 -14 71 13 26 25 35 49
20 58 -5 3 -29 -10 -55 -27z"/>
<path d="M4160 1397 c0 -24 43 -57 74 -57 25 0 19 36 -10 59 -36 28 -64 27
-64 -2z"/>
<path d="M896 1219 c-26 -20 -35 -53 -17 -63 15 -9 76 38 79 62 4 29 -26 30
-62 1z"/>
<path d="M3550 826 c0 -7 7 -19 15 -26 12 -10 16 -9 21 3 3 9 3 21 -1 27 -9
15 -35 12 -35 -4z"/>
<path d="M1525 743 c-32 -31 -41 -93 -15 -93 26 0 64 68 54 95 -8 19 -19 19
-39 -2z"/>
<path d="M1727 663 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>
<path d="M3197 653 c-8 -14 30 -98 44 -98 20 0 23 52 4 81 -15 23 -39 32 -48
17z"/>
<path d="M2997 583 c-13 -13 -7 -61 11 -83 27 -33 46 -17 39 33 -6 45 -31 70
-50 50z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
